import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { checkAuditFetching, getAuditDataCount } from "selectors/audit";
import { checkTransactionsFetching } from "selectors/transactions";
import { getCustomReportsData } from "selectors/customReports";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AmountDeviationFilters from "./lib/AmountDeviationFilters";
import AuditSections from "nlib/pages/AuditPage/AuditSections";
import BulkActions from "nlib/common/TransactionsTable/lib/BulkActions";
import Constants from "const/Constants";
import EmptyState from "nlib/pages/AuditPage/lib/EmptyState";
import Filters from "./lib/Filters";
import React, { useEffect, useMemo, useRef } from "react";
import SimilarPayeesTable from "./lib/SimilarPayeesTable";
import TransactionsTable from "./lib/TransactionsTable";
import UnusualAmountTable from "./lib/UnusualAmountTable";
import UnusualCategoriesTable from "./lib/UnusualCategoriesTable";
import UnusualTaxesTable from "./lib/UnusualTaxesTable";
import Utils from "utils/Utils";
import useAuditUtils from "hooks/useAuditUtils";
import useEnvVars from "hooks/useEnvVars";

const { AUDIT_SECTIONS, AUDIT_SUBSECTIONS_ROUTES } = Constants;

const TransactionsContent = (props) => {
  const {
    auditData,
    refetchData,
    disabled,
    count,
    simplifiedLayout,
    currentRoute,
    onEditReportClick,
    onDeleteReportClick
  } = props;

  const [{ pageSize = String(Constants.TABLE_PAGE_SIZE), page }, setEnvVars] = useEnvVars();

  const scrollToElementRef = useRef();

  const { uiTexts } = useSelector(getTextsData);

  const auditFetching = useSelector(checkAuditFetching);

  const transactionsFetching = useSelector(checkTransactionsFetching);

  const customReportsData = useSelector(getCustomReportsData);

  const auditDataCount = useSelector(getAuditDataCount);

  const currentSectionData = Utils.arrayFind(AuditSections, "route", AUDIT_SECTIONS.TRANSACTIONS);

  const currentItemData = Utils.arrayFind(currentSectionData.items, "route", currentRoute)
    || Utils.arrayFindById(customReportsData, currentRoute);

  const dataFetching = auditFetching || transactionsFetching;

  const {
    transactionsState,
    selectedIds,
    allItemsSelected,
    closedBookDateTransactionIds,
    selectableTransactionIds,
    setSelectedIds,
    handleTransactionChange,
    handleBulkActionsEdit,
    handleBulkActionsCancel,
    handleHeaderCheckBoxChange
  } = useAuditUtils({ transactionsData: auditData, currentRoute, refetchData });

  const allSelectedUsesItems = useMemo(() => {
    return selectedIds.every((id) => Utils.arrayFindById(auditData, id)?.usesItems);
  }, [auditData, selectedIds]);

  useEffect(() => {
    scrollToElementRef.current.scrollIntoView({ behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    setSelectedIds((prev) => prev.filter((id) => Utils.arrayFindById(auditData, id)));
  }, [auditData, setSelectedIds]);

  useEffect(() => {
    if (dataFetching || auditData.length || (!count && !auditDataCount) || !page) {
      return;
    }
    setEnvVars({ page: Math.ceil((auditDataCount || count) / pageSize) });
  }, [
    page,
    pageSize,
    dataFetching,
    auditData.length,
    count,
    auditDataCount,
    setEnvVars
  ]);

  const title = currentItemData?.id ? currentItemData.name : uiTexts[currentItemData?.nameLangId];

  return (
    <>
      <div ref={scrollToElementRef} />
      <div className={Css.header}>
        <div className={Css.title} title={title}>
          {`${uiTexts[currentSectionData.nameLangId]}: ${title}`}
        </div>
        {currentRoute === AUDIT_SUBSECTIONS_ROUTES.AMOUNT_DEVIATION && (
          <AmountDeviationFilters disabled={disabled} />
        )}
        {!!currentItemData?.id && (
          <div className={Css.buttons}>
            <Button primary light icon={Icons.PencilSimple} onClick={onEditReportClick}>{uiTexts.edit}</Button>
            <Button danger light icon={Icons.Trash} onClick={onDeleteReportClick}>{uiTexts.delete}</Button>
          </div>
        )}
      </div>
      {!!selectedIds.length && (
        <BulkActions
          audit
          disabled={disabled}
          usesItems={allSelectedUsesItems}
          showMarkAsCorrect={currentRoute !== AUDIT_SUBSECTIONS_ROUTES.ALL_CATEGORIZED}
          selectedTransactions={selectedIds}
          onCancel={handleBulkActionsCancel}
          onEdit={handleBulkActionsEdit} />
      )}
      <Filters disabled={disabled} />
      <div className={Css.content}>
        {auditData.length
          ? (() => {
            switch (currentRoute) {
              case AUDIT_SUBSECTIONS_ROUTES.UNUSUAL_CATEGORY:
                return (
                  <UnusualCategoriesTable
                    disabled={dataFetching}
                    currentRoute={currentRoute}
                    refetchData={refetchData} />
                );
              case AUDIT_SUBSECTIONS_ROUTES.UNUSUAL_AMOUNT:
                return (
                  <UnusualAmountTable
                    disabled={dataFetching}
                    currentRoute={currentRoute}
                    refetchData={refetchData} />
                );
              case AUDIT_SUBSECTIONS_ROUTES.UNUSUAL_TAX:
                return (
                  <UnusualTaxesTable
                    disabled={dataFetching}
                    currentRoute={currentRoute}
                    refetchData={refetchData} />
                );
              case AUDIT_SUBSECTIONS_ROUTES.SIMILAR_PAYEE:
                return (
                  <SimilarPayeesTable
                    disabled={dataFetching}
                    currentRoute={currentRoute}
                    refetchData={refetchData} />
                );
              default:
                return (
                  <TransactionsTable
                    key={currentRoute}
                    disabled={disabled || dataFetching}
                    simplifiedLayout={simplifiedLayout}
                    data={auditData}
                    count={count}
                    selectedIds={selectedIds}
                    allItemsSelected={allItemsSelected}
                    selectableTransactionIds={selectableTransactionIds}
                    closedBookDateTransactionIds={closedBookDateTransactionIds}
                    transactionsState={transactionsState}
                    currentRoute={currentRoute}
                    refetchData={refetchData}
                    setSelectedIds={setSelectedIds}
                    onHeaderCheckBoxChange={handleHeaderCheckBoxChange}
                    onTransactionChange={handleTransactionChange} />
                );
            }
          })()
          : <EmptyState fetching={dataFetching} />}
      </div>
    </>
  );
};

export default TransactionsContent;
